<template>
    <v-container>
        <page-filters-section :overlay="false">
            <v-btn v-for="(elem, i) in filterElementOptions"
                   :key="i"
                   class="text-capitalize"
                   @click="filterByElement(elem)"
                   v-bind:class="filters.element.toLowerCase() === elem ? 'current': ''"
            >
                {{ elem }}
            </v-btn>
        </page-filters-section>

        <div class="page-content">
            <v-row v-if="!isWalletConnected" class="pt-15">
                <v-col cols="12">
                    <connect-wallet></connect-wallet>
                </v-col>
            </v-row>

            <v-row v-if="isWalletConnected" class="">
                <v-progress-circular
                    v-if="loading"
                    size="300"
                    indeterminate
                    style="width: 100% !important;"
                ></v-progress-circular>
                <v-col v-if="0 === lands.length && !loading">
                    <h3 class="text-center mt-10">- No Lands -</h3>
                </v-col>
                <div v-for="land in sortedLands" :key="land.id" class="pa-1 ma-3" v-show="shouldDisplayMiniLands(land)">
                    <router-link :to="{ name: 'Land', params: {id: land.id}}">
                        <mini-land
                            :item="land"
                        ></mini-land>
                    </router-link>
                </div>
            </v-row>

            <v-row v-if="lands.length > 0" class="text-center mt-5 mb-5">
                <v-pagination
                    class="mx-auto"
                    v-model="page"
                    :length="paginationLength"
                    total-visible="10"
                    @input="onPageChange"
                ></v-pagination>
            </v-row>
        </div>

    </v-container>
</template>

<script>
import PageFiltersSection from "../components/page-filters-section";
import {apiServiceVue} from "../services/apiService.vue";
import MiniLand from "../components/assets/lands/mini-land";
import {NoWalletError} from "@/errors/WalletErrors";
import ConnectWallet from "@/views/ConnectWallet";

const ITEMS_PER_PAGE = 33;

export default {
    components: {ConnectWallet, MiniLand, PageFiltersSection},
    data() {
        return {
            page: 1,
            totalItems: 0,
            lands: [],
            filterElementOptions: ['all', 'fire', 'water', 'nature'],
            filters: {
                element: 'all',
            },
            loading: true,
        };
    },
    watch: {},
    mounted: function () {
        if (this.isWalletConnected) {
            this.onPageChange();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.onPageChange();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        sortedLands() {
            return this.lands.sort((a, b) => (a.id - b.id));
        },
        paginationLength() {
            return Math.ceil(this.totalItems / ITEMS_PER_PAGE);
        }
    },
    methods: {
        onPageChange() {
            this.fetchMyLands((this.page - 1) * ITEMS_PER_PAGE, ITEMS_PER_PAGE);
        },
        shouldDisplayMiniLands(land) {
            return this.filters.element !== 'all' ? land.wizarre && land.wizarre.element.toLowerCase() === this.filters.element.toLowerCase() : true;
        },
        filterByElement(element) {
            this.filters.element = element;
        },
        fetchMyLands(offset = 0, limit = 100) {
            if (!this.web3.address) {
                throw new NoWalletError();
            }
            this.loading = true;
            this.lands = [];

            const url = process.env.VUE_APP_LAND_NFT_SERVER_URL + 'wallet/' + this.web3.address;
            apiServiceVue.get(url, {
                offset: offset,
                limit: limit,
                withTotal: true,
            })
                .then(res => {
                    this.totalItems = res.data.total;
                    const lands = res.data.items;
                    if (lands && lands.length > 0) {
                        this.lands = lands;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch Lands failed'
                    });
                });
        }
    },
}
</script>

<style scoped>
>>> .top-section .v-btn.current .v-btn__content {
    color: var(--v-primary-base);
}

.page-content {
    margin-top: 180px;
}
</style>
