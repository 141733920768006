var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "page-filters-section",
        { attrs: { overlay: false } },
        _vm._l(_vm.filterElementOptions, function (elem, i) {
          return _c(
            "v-btn",
            {
              key: i,
              staticClass: "text-capitalize",
              class:
                _vm.filters.element.toLowerCase() === elem ? "current" : "",
              on: {
                click: function ($event) {
                  return _vm.filterByElement(elem)
                },
              },
            },
            [_vm._v(" " + _vm._s(elem) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          !_vm.isWalletConnected
            ? _c(
                "v-row",
                { staticClass: "pt-15" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_c("connect-wallet")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isWalletConnected
            ? _c(
                "v-row",
                {},
                [
                  _vm.loading
                    ? _c("v-progress-circular", {
                        staticStyle: { width: "100% !important" },
                        attrs: { size: "300", indeterminate: "" },
                      })
                    : _vm._e(),
                  0 === _vm.lands.length && !_vm.loading
                    ? _c("v-col", [
                        _c("h3", { staticClass: "text-center mt-10" }, [
                          _vm._v("- No Lands -"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.sortedLands, function (land) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.shouldDisplayMiniLands(land),
                            expression: "shouldDisplayMiniLands(land)",
                          },
                        ],
                        key: land.id,
                        staticClass: "pa-1 ma-3",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Land", params: { id: land.id } },
                            },
                          },
                          [_c("mini-land", { attrs: { item: land } })],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.lands.length > 0
            ? _c(
                "v-row",
                { staticClass: "text-center mt-5 mb-5" },
                [
                  _c("v-pagination", {
                    staticClass: "mx-auto",
                    attrs: {
                      length: _vm.paginationLength,
                      "total-visible": "10",
                    },
                    on: { input: _vm.onPageChange },
                    model: {
                      value: _vm.page,
                      callback: function ($$v) {
                        _vm.page = $$v
                      },
                      expression: "page",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }